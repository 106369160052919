.Cities {
  .HomePageSlider-slide {
    padding-right: 0;
  }
}

.Cities-caption {
  display: flex;
  align-items: center;
  padding: $spacing--base * 3 0;
}

.Cities-captionImage {
  height: 36px;
  width: 36px;
  margin-right: $spacing--base;
}

.Cities-captionText {
  display: flex;
  flex-direction: column;
}

.Cities-cityName {
  @include typo--heading-3;
}

.Cities-cityDescription {
  @include typo--body-2;
}
