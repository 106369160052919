.HomePageDateFilter {
  display: flex;
}

.HomePageDateFilter-widget-title {
  display: block;
  text-align: center;
  color: $palette--medium-gray;
}

.HomePageDateFilter-from {
  margin-right: $spacing--medium;
  border-right: 1px solid $palette--light-gray;
  padding-right: $spacing--medium;
}

.SearchBarForm-Input-DateFilter {
  .popper-reference {
    width: 100%;
    height: 100%;
  }

  .popper-InnerReference {
    width: 100%;
    height: 100%;
  }

  .popper[data-placement^="bottom"] {
    margin-top: -12px;
  }

  .popper__arrow {
    display: none;
  }
}

.SearchBarForm-DateFilterToggleButton {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  text-align: left;
  background: none;
  outline: 0;
  border: 0;
}
