$slide-padding: 7px;

.HomePageSlider-header {
  display: flex;
  margin: 0 $spacing--base * 4 $spacing--base * 5;
  max-width: $homePage-content--maxWidth;
  align-items: center;

  &.ContentContainer {
    padding: 0;
    margin-bottom: $spacing--base * 5;

    @include gt($homePage-breakpoint--small) {
      margin-bottom: $spacing--base * 7;
    }
  }
}

.HomePageSlider-header-arrows {
  display: none;

  @include gt($homePage-breakpoint--small) {
    display: flex;
    margin-left: auto;
    align-self: flex-end;
  }

  .HomePageSlider-header-arrows-arrow:first-child {
    margin-right: $spacing--base * 3;
  }
}

.HomePageSlider-header-arrows-arrow {
  background-color: $palette--tint-2-grey;
  padding: $spacing--base * 2;
  border-radius: 50%;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;

  .non-touch &:hover {
    background: $palette--tint-3-grey;

    .IconButton-icon {
      background: none;
      border: none;
    }
  }

  &:disabled {
    background-color: $palette--tint-1-grey;
    border: 1px solid $palette--tint-2-grey;

    .non-touch &:hover {
      cursor: auto;
      background-color: $palette--tint-1-grey;
    }
  }

  .IconButton-icon {
    background: none;
    border: none;
  }
}

.HomePageSlider-scrollContainer {
  position: relative;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0 var(--page-margin);

  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  .HomePageSlider--hasHover & {
    @include gt($homePage-breakpoint--xxlarge) {
      scroll-padding: 0 $slide-padding;
      margin: 0 -$slide-padding;
    }

    &:after {
      min-width: calc(var(--page-margin) - #{$slide-padding});
    }
  }

  &:after {
    content: "";
    min-width: var(--page-margin);

    @include gt($homePage-breakpoint--xxlarge) {
      min-width: 0;
    }
  }
}

.HomePageSlider-slide {
  scroll-snap-align: start start;
  position: relative;
  padding-right: $spacing--base * 3;
  flex-shrink: 0;

  .HomePageSlider--hasHover & {
    scroll-snap-margin-left: -$slide-padding;
    scroll-margin-left: -$slide-padding;

    &:last-child {
      scroll-margin-right: -$slide-padding;
    }
  }

  @include gt($homePage-breakpoint--small) {
    padding-right: $spacing--base * 5;
  }

  &:first-of-type {
    margin-left: var(--page-margin);

    @include gt($homePage-breakpoint--xxlarge) {
      margin-left: 0;
    }
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.HomePageSlider-headerTitle * {
  @include typo--heading-3;

  @include gt($homePage-breakpoint--small) {
    @include typo--heading-2;
  }
}

.HomePageSlider-title {
  color: $palette--grey;
}

.HomePageSlider-subtitle {
  color: $color-2019--font-primary;
}
