@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/media.scss";
@import "./LandlordSection/LandlordSection.scss";
@import "./HomePageSlider/HomePageSlider.scss";
@import "./HomePageSlider/Slide.scss";
@import "./TrustpilotReviews/TrustpilotReviews.scss";
@import "./InfoSection/InfoSection.scss";
@import "./Collections/Collections.scss";
@import "./Collections/Caption.scss";
@import "./Cities/Cities.scss";
@import "./SearchBar/SearchBar.scss";

#main {
  --page-margin: #{$spacing--base * 4};

  @include gt($homePage-breakpoint--small) {
    --page-margin: #{$spacing--base * 6};
  }

  @include gt($homePage-breakpoint--medium) {
    --page-margin: #{$spacing--base * 16};
  }

  @include gt($homePage-breakpoint--large) {
    // in between 95px and 192px, gutter is fluid and relative to screen size
    --page-margin: 10vw;
  }

  @include gt($homePage-breakpoint--xxlarge) {
    --page-margin: auto;
  }
}

.HomePage {
  .Footer-container {
    margin: 0 var(--page-margin);
  }
}

.HomepageSplash {
  display: flex;
  flex-direction: column;
  margin-top: -$height-app-header; // compensate for sticky navbar

  background: linear-gradient(
      155.25deg,
      rgba(107, 37, 223, 0) 48.74%,
      #4b2ae6 92.9%
    ),
    linear-gradient(335.16deg, rgba(107, 37, 223, 0) 63.62%, #5826e4 99.6%),
    linear-gradient(50.88deg, rgba(107, 37, 223, 0) 28.21%, #8622e3 83.2%),
    #6c22de;
  min-height: 500px;
  height: 78vh;
  max-height: 650px;

  // compensate for sticky navbar - for mobile it's 64px & for tablet/desktop it's 70px
  padding-top: 64px;

  @include gt(medium) {
    padding-top: 70px;
  }
}

.Homepage-AppHeader {
  position: sticky;
  top: 0;
  transition: all 0.3s ease-out;
}

.Homepage-Search {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  padding-bottom: clamp(15px, 3vh, 60px);
}

.Homepage-Search-inner {
  width: 100%;
  max-width: 1350px;
  margin: 0 var(--page-margin);

  // 1620px = 1350px (maxwidth) + 2 * 10vw (page margin)
  @include gt(1620px) {
    margin: 0;
  }
}

.ContentContainer {
  margin: 0 var(--page-margin);
  max-width: $homePage-content--maxWidth;

  &.ContentContainer--fullBleed {
    margin-right: 0;
    margin-left: 0;
    max-width: none;

    @include gt($homePage-breakpoint--xxlarge) {
      max-width: $homePage-content--maxWidth;
      margin: 0 auto;
    }
  }

  @include gt($homePage-breakpoint--xxlarge) {
    margin: 0 auto;
  }
}

.Homepage-CollectionsSection {
  padding-top: $spacing--base * 9;

  @include gt($homePage-breakpoint--small) {
    padding-top: $spacing--base * 12;
  }

  @include gt($homePage-breakpoint--xlarge) {
    padding-top: $spacing--base * 15;
  }
}

.Homepage-InfoSection {
  padding: $spacing--base * 15 0 $spacing--base * 12;

  @include gt($homePage-breakpoint--small) {
    padding: $spacing--base * 20 0 $spacing--base * 15;
  }

  @include gt($homePage-breakpoint--xlarge) {
    padding: $spacing--base * 26 0 $spacing--base * 16;
  }
}

.Homepage-CitiesSection {
  padding-top: $spacing--base * 12;

  @include gt($homePage-breakpoint--small) {
    padding-top: $spacing--base * 15;
  }
}

.Homepage-TrustpilotSection {
  margin-bottom: $spacing--base * 15;

  @include gt($homePage-breakpoint--small) {
    margin-bottom: $spacing--base * 24;
  }

  @include gt($homePage-breakpoint--xlarge) {
    margin-bottom: $spacing--base * 25;
  }
}

.Homepage-FAQSection {
  padding: $spacing--base * 15 0;

  @include gt($homePage-breakpoint--small) {
    padding: $spacing--base * 20 0;
  }

  @include gt($homePage-breakpoint--xlarge) {
    padding: $spacing--base * 25 0 $spacing--base * 20;
  }

  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  img {
    max-width: 100%;
  }
}

.Homepage-LandlordSection {
  padding: $spacing--base * 12 0;

  @include gt($homePage-breakpoint--small) {
    padding: $spacing--base * 15 0 $spacing--base * 17;
  }
}

.HomePage-headline {
  font-weight: $font-weight--bold;
  font-size: 36px;
  line-height: 49px;
  color: $palette--pure-white;
  text-align: center;

  @include gt($homePage-breakpoint--small) {
    font-size: 46px;
    line-height: 57px;
  }
}

.HomePage-subheadline {
  font-weight: $font-weight--bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: rgba($color: $palette--pure-white, $alpha: 0.75);
  margin-top: $spacing--base;
  margin-bottom: $spacing--base * 5;

  @include gt($homePage-breakpoint--large) {
    font-size: 24px;
    line-height: $line-height--medium;
    margin-bottom: 45px;
  }
}

.Homepage-grayBackground,
.Homepage-purpleBackground {
  width: 100%;
  margin: 0 auto;

  @include gt($homePage-breakpoint--small) {
    padding-top: $spacing--base * 15;
  }

  @include gt($homePage-breakpoint--medium) {
    padding-top: $spacing--base * 20;
  }
}

.Homepage-bottomGrayGradient {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 263px;
    background: linear-gradient(0deg, #f7f9fa 0%, rgba(247, 249, 250, 0) 100%);

    @include gt($homePage-breakpoint--small) {
      height: 175px;
    }
  }
}

.Homepage-grayBackground {
  background: linear-gradient(85.57deg, #edf1f4 23.38%, #f4f3ed 87.74%);
}

.Homepage-purpleBackground {
  background: linear-gradient(
      155.25deg,
      rgba(107, 37, 223, 0) 48.74%,
      #4b2ae6 92.9%
    ),
    linear-gradient(335.16deg, rgba(107, 37, 223, 0) 63.62%, #5826e4 99.6%),
    linear-gradient(50.88deg, rgba(107, 37, 223, 0) 28.21%, #8622e3 83.2%),
    #6c22de;
}

.HomePage_ukraine-flag {
  margin-right: $spacing--micro;
  display: flex;
}

.HomePage_banner_link {
  margin-bottom: $spacing--medium;
  align-self: center;
  margin-left: $spacing--base * 4;
  margin-right: $spacing--base * 4;
}

.HomePage_ukraineButton_svg {
  border: 1px solid $palette--pure-white;
  border-radius: 100%;
}
