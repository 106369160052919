.TrustpilotReviews-header-headline {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  @include typo--heading-3;
  margin-bottom: $spacing--base * 9;

  @include gt($homePage-breakpoint--small) {
    @include typo--heading-2;
    display: flex;
  }
}

.TrustPilotWidget {
  max-height: 200px;
  height: min-content;

  html & {
    height: max-content;
  }
}
