@import "../../../../../../styles/variables.scss";

.SearchBarDateRangePicker {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: default;
}

.SearchBarDateRangePicker-label {
  padding-left: 5px;
  @include typo--body-3;
  font-weight: 600;
  text-transform: uppercase;
}

.SearchBarDateRangePicker-trigger {
  @include typo--heading-3;
  color: $palette--black;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.SearchBarDateRangePicker-previewDateButton {
  @include typo--heading-3;
  display: inline-flex;
  color: $palette--black;
  border: none;
  background-color: transparent;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;

  &--inactive {
    color: $palette--silver;
  }

  &--active {
    color: $palette--light-purple;
    background-color: $palette--pale-purple;
  }

  &--selected {
    color: $palette--purple;
    background-color: $palette--pale-purple;
  }
}

.SearchBarDateRangePicker-dateSeparator {
  color: $palette--black;

  &--inactive {
    color: $palette--silver;
  }
}

.SearchBarDateRangePicker-popover.DateRangePickerTrigger-popover {
  margin-top: 22px;
}
