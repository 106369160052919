.Slide {
  display: flex;
  flex-direction: column;
  min-width: 287px;
  position: relative;
  padding: $slide-padding;
  height: 100%;

  width: calc(
    (100vw - var(--scrollbarWidth) - 2 * var(--page-margin)) / 4 +
      (#{$slide-padding} / 2)
  );

  @include gt($homePage-breakpoint--xxlarge) {
    width: calc(#{$homePage-content--maxWidth} / 4 + (#{$slide-padding} / 2));
  }

  .non-touch &:hover {
    .Slide-coverImage {
      transform: scale3d(1.03, 1.03, 1);
    }

    &::after {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $palette--lighter-pink;
    opacity: 0;
    z-index: -1;
    transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: $border-radius--large;
    transform: scale(0.95, 0.95);
  }
}

.Slide-figure {
  height: 0;
  padding-bottom: 128.2%;
  border-radius: $border-radius--medium;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 15px 30px rgba(55, 57, 86, 0.1);
  position: relative;
}

.Slide-coverImage {
  transform: scale3d(1, 1, 1);
  transform-style: preserve-3d;
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 1;
  position: absolute;
}

.Slide-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
