@import "../../styles/variables.scss";
@import "../../styles/media.scss";

$transition: 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

.CardImageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .non-touch &:hover {
    .CardImageHeader-imageContainer img {
      transform: scale(1.03, 1.03);
    }

    .CardImageHeader-cta-arrow-container-inner {
      transform: translateX(0);
      animation: arrowContainerAnimation $transition;
    }
  }
}

.CardImageHeader-imageContainer {
  height: 0;
  padding-bottom: 55%;
  width: 100%;
  border-radius: $spacing--base * 2 $spacing--base * 2 0 0;
  overflow: hidden;

  @include gt($homePage-breakpoint--small) {
    padding-bottom: 36%;
  }

  @include gt($homePage-breakpoint--medium) {
    padding-bottom: 50%;
  }

  @include gt($homePage-breakpoint--large) {
    padding-bottom: 40%;
  }

  @include gt($homePage-breakpoint--xlarge) {
    padding-bottom: 34%;
  }

  @include gt($homePage-breakpoint--xxlarge) {
    padding-bottom: 32%;
  }

  .CoverImage {
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    transform: scale(1, 1);
    transition: transform $transition;
  }
}

.CardImageHeader-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.CardImageHeader-content {
  overflow: hidden;
  border-radius: 0 0 $spacing--base * 2 $spacing--base * 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing--base * 4 $spacing--base * 4 $spacing--base * 6
    $spacing--base * 4;
  flex: 1;
  transition: background $transition;

  @include gt($homePage-breakpoint--small) {
    padding: $spacing--base * 5 $spacing--base * 9 $spacing--base * 7
      $spacing--base * 9;
  }
  @include gt($homePage-breakpoint--medium) {
    padding: $spacing--base * 5 $spacing--base * 10 $spacing--base * 7
      $spacing--base * 10;
  }
  @include gt($homePage-breakpoint--xlarge) {
    padding: $spacing--base * 5 $spacing--base * 16 $spacing--base * 7
      $spacing--base * 16;
  }
  @include gt($homePage-breakpoint--xxlarge) {
    padding: $spacing--base * 9 $spacing--base * 20 $spacing--base * 11
      $spacing--base * 20;
  }
}

.CardImageHeader-content-title {
  @include typo--heading-3;
  text-align: center;
  margin-bottom: $spacing--base * 2;
  max-width: 250px;

  @include gt($homePage-breakpoint--small) {
    @include typo--heading-2;
    max-width: 390px;
  }
}

.CardImageHeader-content-description {
  @include typo--body-1;
  text-align: center;
  margin-bottom: $spacing--base * 5;

  @include gt($homePage-breakpoint--xxlarge) {
    margin-bottom: $spacing--base * 6;
  }
}

.CardImageHeader-cta {
  display: flex;
  align-items: center;
  margin-top: auto;
  font-weight: $font-weight--bold;
  padding: $spacing--base * 2 $spacing--base * 4;
  border-radius: 30px;
  transition: background 150ms;
}

.CardImageHeader-cta-arrow {
  min-width: 15px;
  min-height: 15px;
  margin-left: 8px;
}

.CardImageHeader.Purple {
  .CardImageHeader-content {
    background: $palette--purple;

    .CardImageHeader-content-title,
    .CardImageHeader-content-description,
    .CardImageHeader-cta {
      color: white;
    }
  }

  .non-touch &:hover .CardImageHeader-content {
    background: #6724c9;
  }
}

.CardImageHeader.Peach {
  .CardImageHeader-content {
    background: $palette--pale-red;

    .CardImageHeader-cta .CardImageHeader-cta-arrow-fillColor {
      fill: black;
    }
  }

  .non-touch &:hover .CardImageHeader-content {
    background: $palette--pale-red-2;
  }
}

.CardImageHeader-cta-arrow-container {
  width: 26px;
  height: 26px;
  overflow: hidden;
}

.CardImageHeader-cta-arrow-container-inner {
  display: flex;
  height: 100%;
  align-items: center;
  // transition: transform $transition;
  transform: translateX(-100%);
}

@keyframes arrowContainerAnimation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
