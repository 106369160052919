.HomePageSlider.Collections {
  .HomePageSlider-slide {
    padding-right: 0;
  }

  .HomePageSlider-header {
    margin-bottom: $spacing--base * 4;
  }
}

html[lang="en"] .HomePageSlider.Collections .Caption-subtitle {
  max-width: 280px;
  margin: 0 auto;
}

html[lang="en"] .Slide.CATEGORY_PETS .Caption-subtitle {
  max-width: 150px;
  margin: 0 auto;
}

html[lang="de"] .HomePageSlider.Collections .Caption-subtitle {
  max-width: 280px;
  margin: 0 auto;
}
