@import "../../../../../styles/variables.scss";
@import "../../../../../styles/media.scss";

.HomePageBanner {
  display: flex;
  justify-content: center;
  min-height: 40px;
  margin: 0 20px;
}

.HomePageBanner__wrapper {
  display: flex;
  align-items: center;
  gap: $spacing--micro;
  justify-content: space-between;
  padding: $spacing--base $spacing--small;
  border-radius: $border-radius-inputSearch;
  min-width: 100%;

  @include gt(small) {
    padding: $spacing--base $spacing--small;
  }

  @include gt($homePage-breakpoint--small) {
    min-width: 535px;
  }
}

.HomePageBanner--light .HomePageBanner__wrapper {
  border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.35);
  background: rgba($color: $palette--pure-white, $alpha: 0.2);

  &:hover {
    background: rgba($color: $palette--pure-white, $alpha: 0.3);
  }
}

.HomePageBanner--dark .HomePageBanner__wrapper {
  border: 1px solid $palette--tint-3-grey;
  background: $palette--tint-1-grey;

  &:hover {
    background: $palette--tint-2-grey;
  }
}

.HomePageBanner__inner {
  display: flex;
  align-items: center;
}

.HomePageBanner__arrow {
  display: flex;
  width: 16px;
}

.HomePageBanner__PrimaryText {
  margin-right: $spacing--base;
  font-weight: $font-weight--bold;
  line-height: 20px;
}

.HomePageBanner--light .HomePageBanner__PrimaryText {
  color: $palette--pure-white;
}

.HomePageBanner--dark .HomePageBanner__PrimaryText {
  color: $palette--black;
}

.HomePageBanner__SecondaryText {
  margin-right: $spacing--base;
  font-weight: $font-weight--bold;
  line-height: 20px;
}

.HomePageBanner--light .HomePageBanner__SecondaryText {
  color: rgba($color: $palette--pure-white, $alpha: 0.75);
}

.HomePageBanner--dark .HomePageBanner__SecondaryText {
  color: $palette--dark-grey;
}
