@import "../../../../../styles/variables.scss";

.HomePage-LandlordSection-title {
  @include typo--heading-3;
  line-height: $line-height--small;
  color: $palette--grey;
}

.HomePage-LandlordSection-subtitle {
  @include typo--heading-3;
  color: $palette--black;
  margin-bottom: $spacing--base * 5;

  @include gt($homePage-breakpoint--small) {
    margin-bottom: $spacing--base * 7;
  }
}

.HomePage-LandlordSection-CardContainer {
  display: flex;
  flex-direction: column;

  @include gt($homePage-breakpoint--medium) {
    flex-direction: row;
    justify-content: space-between;
  }

  .CardImageHeader:last-of-type {
    margin-top: $spacing--base * 4;

    @include gt($homePage-breakpoint--small) {
      margin-top: $spacing--base * 6;
    }

    @include gt($homePage-breakpoint--medium) {
      margin-top: 0;
    }
  }
}

.HomePage-LandlordSection-Card {
  @include gt($homePage-breakpoint--medium) {
    width: calc(50% - #{$spacing--base * 2});
  }

  @include gt($homePage-breakpoint--large) {
    width: calc(50% - #{$spacing--base * 3});
  }

  @include gt($homePage-breakpoint--xlarge) {
    width: calc(50% - #{$spacing--base * 4});
  }
}
