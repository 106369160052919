.Caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $spacing--small;
}

.Caption-title {
  height: 36px;
}

.Caption-subtitle {
  color: $palette--dark-gray-5;
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  padding: 0 7px;
  text-align: center;
  padding-bottom: $spacing--base * 4;
}
