@import "../../../../../styles/variables.scss";

.InfoSection-headline {
  @include typo--heading-2;
  margin-bottom: $spacing--base * 12;
  white-space: pre-line;
  max-width: 1200px;

  @include gt($homePage-breakpoint--small) {
    font-size: 30px;
    line-height: 38px;
    font-weight: $font-weight--bold;
    color: $palette--black;
  }

  @include gt($homePage-breakpoint--medium) {
    margin-bottom: $spacing--base * 16;
  }

  @include gt($homePage-breakpoint--xxlarge) {
    font-size: 36px;
    line-height: 46px;
  }
}

.InfoSection-headline-highlight {
  background: linear-gradient(89.87deg, #732dd9 24.9%, #4c2be6 52.92%);
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.InfoSection-container {
  @include gt($homePage-breakpoint--medium) {
    display: flex;
    justify-content: space-between;
  }
}

.InfoSection-container-item {
  border-bottom: 1px solid $palette--tint-4-grey;
  padding: $spacing--base * 5 0;
  flex: 1;

  @include gt($homePage-breakpoint--small) {
    display: inline-flex;
    padding: $spacing--base * 7 0;
  }

  @include gt($homePage-breakpoint--medium) {
    display: block;
    border-bottom: none;
    border-right: 1px solid $palette--tint-4-grey;
    border-left: 1px solid $palette--tint-4-grey;
    padding: 0 $spacing--base * 7;

    &:first-of-type,
    &:last-of-type {
      border: none;
    }
  }

  @include gt($homePage-breakpoint--large) {
    padding: 0 $spacing--base * 9;
  }

  @include gt($homePage-breakpoint--xlarge) {
    padding: 0 $spacing--base * 12;
  }

  @include gt($homePage-breakpoint--xxlarge) {
    padding: 0 $spacing--base * 20;
  }

  &:first-of-type {
    padding-top: 0;
    padding-left: 0;
  }

  &:last-of-type {
    border-bottom: none;
    border-right: none;
    padding-bottom: 0;

    @include gt($homePage-breakpoint--medium) {
      padding-right: 0;
    }
  }
}

.InfoSection-container-item-icon {
  width: 50px;
  min-width: 50px;
  height: 50px;

  @include gt($homePage-breakpoint--small) {
    margin-right: $spacing--base * 8;
  }
}

.InfoSection-container-item-text {
  @include gt($homePage-breakpoint--large) {
    max-width: 275px;
  }
  @include gt($homePage-breakpoint--xlarge) {
    max-width: 350px;
  }
}

.InfoSection-container-item-text-title {
  @include typo--heading-3;
  margin-bottom: $spacing--base;
}

.InfoSection-container-item-text-description {
  @include typo--body-1;
  color: $palette--dark-grey;
}
