@import "./DateFilter/DateFilter.scss";

.SearchBar {
  display: flex;
  width: 100%;

  border-radius: $border-radius-inputSearch;
  background: $palette--pure-white;
  box-shadow:
    0px 5px 5px rgba(59, 55, 86, 0.15),
    0px 10px 20px rgba(55, 57, 86, 0.3);
}

.SearchBarForm {
  width: 100%;
  display: flex;
  align-items: center;
}

.SearchBarForm-Column {
  flex: 1;
}

.SearchBarForm-Input {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: $spacing--medium;
  border-radius: $border-radius-base * 8;
  cursor: pointer;

  height: 100%;

  @include gt($homePage-breakpoint--medium) {
    padding: 0 55px;
  }
}

.SearchBarForm-Input-content {
  width: 100%;
}

.SearchBarForm-Input-title {
  display: block;

  font-weight: $font-weight--bold;
  font-size: $font-size--g;
  line-height: 18px;

  text-transform: uppercase;
  color: $palette--black;

  cursor: pointer;
}

.SearchBarForm-InputAutocomplete.InputAutocomplete-suggestionsBox {
  margin-top: 26px;
}

.SearchBarForm-separator {
  display: none;

  width: 1px;
  height: 60px;
  background-color: $palette--light-gray-10;

  margin: 0 $spacing--base;

  @include gt($homePage-breakpoint--small) {
    display: block;
  }
}

.SearchBarForm-Input-DateFilter {
  display: none;

  @include gt($homePage-breakpoint--small) {
    display: block;
  }
}

.SearchBarForm-Input-DateRangePicker {
  display: none;

  @include gt($homePage-breakpoint--small) {
    display: flex;
  }
}

.SearchBarForm-SubmitButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: $palette--lime-green;

  margin: $spacing--base * 2;

  width: 40px;
  height: 40px;

  color: $palette--pure-white;
  font-weight: $font-weight--bold;
  font-size: 20px;
  line-height: 28px;

  border: none;
  border-radius: 50%;
  outline: none;

  cursor: pointer;

  &:hover {
    background: $palette--lime-green-dark;
  }

  &:focus {
    background: $palette--lime-green;
    box-shadow: $box-shadow-focus-designSystemButtonPrimary;
    outline: 0;
  }

  @include gt($homePage-breakpoint--small) {
    width: 223px;
    height: 60px;
    margin: 13px $spacing--small 13px $spacing--base;
    border-radius: $border-radius-base * 10;
  }
}

.SearchBarForm-SubmitButton-icon {
  @include gt($homePage-breakpoint--small) {
    display: none;
  }
}

.SearchBarForm-SubmitButton-text {
  display: none;

  @include gt($homePage-breakpoint--small) {
    display: block;
  }
}

.SearchBarForm-Input-placeholder {
  font-family: $primary-font-stack;
  font-size: 20px;
  line-height: 28px;
  font-weight: $font-weight--bold;
  color: $palette--silver;
  cursor: pointer;

  &--active {
    opacity: 0.75;
  }

  &:hover {
    opacity: 0.75;
  }
}

.SearchBarForm-Input-value {
  font-family: $primary-font-stack;
  font-size: 20px;
  line-height: 28px;
  font-weight: $font-weight--bold;
  color: $palette--black;
  cursor: pointer;
}

.SearchBarForm-Input-DateFilter {
  .popper-enter {
    opacity: 1;
  }

  .popper-exit-active {
    opacity: 1;
  }
}

.RegionSelect-vienna {
  position: relative;
  display: flex;
  align-items: center;
}

.RegionSelect-new {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $spacing--base * 2;
  color: white;
  background-color: $palette--wunder-purple;
  position: absolute;
  right: 10px;
  height: $spacing--base * 4;
  border-radius: $border-radius--large * 2;
  font-size: $font-size--h;
  font-weight: $font-weight--bold;
  cursor: default;
}

.SearchBar-GeocodingSearch {
  width: calc(100% - 40px - 40px); // 40px padding + 40px icon
  flex: 1;
  margin-left: $spacing--base * 4;

  @include gt(xsmall) {
    padding: $spacing--base * 4;
  }

  .SearchBarForm-Input-title {
    display: none;
    @include gt($homePage-breakpoint--small) {
      display: block;
    }
  }

  .GeocodingInputSearch--light .InputText-input {
    padding: 0;
    margin: 0;
    font-size: $font-size--h * 2;
  }

  .GeocodingInputSearchMobile .SearchDrawerTrigger {
    border: none;
    box-shadow: none;
    padding: 0;

    &-Text {
      padding: 0;
      font-size: $font-size--h * 2;
    }

    &-SearchIcon {
      display: none;
    }

    .SearchDrawerTrigger-Text {
      width: 100%;
    }
  }
}
